import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled from 'styled-components'
import { LAYOUT_CONSTANT } from '../../utils/constants'
import { Grid } from '@mui/material'

const middleColumnBlock = () => {
    return (
        <Grid container spacing={2} style={{ marginBottom: '50px' }}>
            <Grid item xs={4} md={3}>
                <Skeleton
                    style={{
                        height: '103px',
                        width: '100%',
                        marginBottom: '5px'
                    }}
                />
            </Grid>

            <Grid item xs={8}>
                <Skeleton
                    style={{
                        height: '24px',
                        width: '30%',
                        marginBottom: '5px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '24px',
                        width: '100%',
                        marginBottom: '5px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '24px',
                        width: '100%',
                        marginBottom: '10px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '24px',
                        width: '70%',
                        marginBottom: '5px'
                    }}
                />
            </Grid>
        </Grid>
    )
}

export const LoadingRoot = () => {
    return (
        <Skeleton
            style={{ height: '50px', width: '300px', marginBottom: '18px' }}
        />
    )
}

export const loadingSpecialPostHeader = () => (
    <Grid item xs={12} md={3} style={{ height: '700px' }}>
        <Skeleton style={{ height: '440px', width: '100%' }} />
    </Grid>
)

export const LoadingHome = () => {
    return (
        <ContainerRoot id='skeleton'>
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <Skeleton
                        style={{
                            height: '408px',
                            width: '100%',
                            marginBottom: '10px'
                        }}
                    />
                    <Skeleton
                        style={{
                            height: '28px',
                            width: '10%',
                            marginBottom: '5px'
                        }}
                    />
                    <Skeleton
                        style={{
                            height: '40px',
                            width: '100%',
                            marginBottom: '5px'
                        }}
                    />
                    <Skeleton
                        style={{
                            height: '40px',
                            width: '100%',
                            marginBottom: '10px'
                        }}
                    />

                    <Skeleton
                        style={{
                            height: '28px',
                            width: '100%',
                            marginBottom: '5px'
                        }}
                    />
                    <Skeleton
                        style={{
                            height: '28px',
                            width: '100%',
                            marginBottom: '10px'
                        }}
                    />

                    <Skeleton
                        style={{
                            height: '22px',
                            width: '30%',
                            marginBottom: '5px'
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    {middleColumnBlock()}
                    {middleColumnBlock()}
                    {middleColumnBlock()}
                    {middleColumnBlock()}
                </Grid>

                <Grid item xs={12} md={3} style={{ height: '700px' }}>
                    <Skeleton style={{ height: '100%', width: '100%' }} />
                </Grid>
            </Grid>
        </ContainerRoot>
    )
}

export const LoadingCandidate = () => {
    return (
        <ContainerRoot>
            <Skeleton style={{ height: '30px', width: '150px' }} />
            <Skeleton
                style={{ height: '50px', width: '350px', margin: '8px 0 44px' }}
            />

            <ContainerLoadingFlexRow>
                <Skeleton
                    style={{ height: '150px', width: '150px' }}
                    borderRadius='150px'
                />
                <ContainerLoadingFlexColumn style={{ width: '155px' }}>
                    <Skeleton
                        style={{
                            height: '75px',
                            width: '100%',
                            marginLeft: '18px',
                            marginBottom: '8px'
                        }}
                    />
                    <Skeleton
                        count={2}
                        style={{
                            height: '25px',
                            width: '100%',
                            marginLeft: '18px'
                        }}
                    />
                </ContainerLoadingFlexColumn>
            </ContainerLoadingFlexRow>
            <ContainerLoadingFlexColumn style={{ marginTop: '44px' }}>
                <Skeleton
                    style={{
                        height: '25px',
                        width: '250px',
                        marginBottom: '8px'
                    }}
                />
                <Skeleton
                    count={1}
                    style={{
                        height: '25px',
                        width: '100%'
                    }}
                />
            </ContainerLoadingFlexColumn>
            <ContainerLoadingFlexColumn style={{ marginTop: '44px' }}>
                <Skeleton
                    style={{
                        height: '25px',
                        width: '250px',
                        marginBottom: '8px'
                    }}
                />
                <Skeleton
                    count={3}
                    style={{
                        height: '25px',
                        width: '100%'
                    }}
                />
            </ContainerLoadingFlexColumn>
            <Line />
            <ContainerLoadingFlexColumn style={{ marginTop: '44px' }}>
                <Skeleton
                    style={{
                        height: '25px',
                        width: '250px',
                        marginBottom: '8px'
                    }}
                />
                <Skeleton
                    count={3}
                    style={{
                        height: '25px',
                        width: '100%'
                    }}
                />
            </ContainerLoadingFlexColumn>
            <Line />
        </ContainerRoot>
    )
}

/**
 * Loading Line
 * @return {React.JSX.Element}
 */
export const LoadingLine = () => (
    <Skeleton
        count={1}
        style={{
            height: '25px',
            width: '100%'
        }}
    />
)

export const LoadingContent = () => (
    <ContainerRoot style={{ maxWidth: '664px' }}>
        <Skeleton
            style={{ height: '20px', width: '100px', marginBottom: '10px' }}
        />
        <Skeleton
            style={{ height: '30px', width: '200px', marginBottom: '10px' }}
        />
        <Skeleton
            style={{ height: '50px', width: '100%', marginBottom: '10px' }}
            count={1}
        />
        <Skeleton
            style={{ height: '50px', width: '50%', marginBottom: '44px' }}
        />

        <Skeleton
            style={{ height: '20px', width: '100%', marginBottom: '6px' }}
            count={5}
        />

        <br />

        <Skeleton
            style={{ height: '20px', width: '100%', marginBottom: '6px' }}
            count={7}
        />

        <br />

        <Skeleton
            style={{ height: '20px', width: '100%', marginBottom: '6px' }}
            count={3}
        />
    </ContainerRoot>
)

export const LoadingCardIndividual = () => (
    <LoadingCardIndividualSearch>
        <Skeleton
            style={{ height: '20px', width: '100px', marginBottom: '6px' }}
        />
        <Skeleton
            style={{ height: '20px', width: '150px', marginBottom: '15px' }}
        />
        <Skeleton
            style={{ height: '30px', width: '100%', marginBottom: '6px' }}
            count={2}
        />
        <Skeleton
            style={{
                height: '15px',
                width: '300px',
                marginBottom: '6px',
                marginTop: '0px'
            }}
        />
    </LoadingCardIndividualSearch>
)

export const LoadingSearchContent = () => (
    <LoadingSearchContentContainer>
        <LoadingCardIndividual />
        <LoadingCardIndividual />
        <LoadingCardIndividual />
        <LoadingCardIndividual />
        <LoadingCardIndividual />
        <LoadingCardIndividual />
        <LoadingCardIndividual />
        <LoadingCardIndividual />
        <LoadingCardIndividual />
        <LoadingCardIndividual />
    </LoadingSearchContentContainer>
)

export const LoadingSubhomeContent = () => (
    <LoadingSubhomeContainer>
        <LoadingCardIndividualSearch>
            <Skeleton
                style={{ height: '20px', width: '100px', marginBottom: '6px' }}
            />
            <Skeleton
                style={{ height: '20px', width: '150px', marginBottom: '15px' }}
            />
            <Skeleton
                style={{ height: '30px', width: '100%', marginBottom: '6px' }}
                count={2}
            />
            <Skeleton
                style={{
                    height: '15px',
                    width: '300px',
                    marginBottom: '6px',
                    marginTop: '0px'
                }}
            />
        </LoadingCardIndividualSearch>
        <LoadingCardIndividualSearch>
            <Skeleton
                style={{ height: '20px', width: '100px', marginBottom: '6px' }}
            />
            <Skeleton
                style={{ height: '20px', width: '150px', marginBottom: '15px' }}
            />
            <Skeleton
                style={{ height: '30px', width: '100%', marginBottom: '6px' }}
                count={2}
            />
            <Skeleton
                style={{
                    height: '15px',
                    width: '300px',
                    marginBottom: '6px',
                    marginTop: '0px'
                }}
            />
        </LoadingCardIndividualSearch>
        <LoadingCardIndividualSearch>
            <Skeleton
                style={{ height: '20px', width: '100px', marginBottom: '6px' }}
            />
            <Skeleton
                style={{ height: '20px', width: '150px', marginBottom: '15px' }}
            />
            <Skeleton
                style={{ height: '30px', width: '100%', marginBottom: '6px' }}
                count={2}
            />
            <Skeleton
                style={{
                    height: '15px',
                    width: '300px',
                    marginBottom: '6px',
                    marginTop: '0px'
                }}
            />
        </LoadingCardIndividualSearch>
        <LoadingCardIndividualSearch>
            <Skeleton
                style={{ height: '20px', width: '100px', marginBottom: '6px' }}
            />
            <Skeleton
                style={{ height: '20px', width: '150px', marginBottom: '15px' }}
            />
            <Skeleton
                style={{ height: '30px', width: '100%', marginBottom: '6px' }}
                count={2}
            />
            <Skeleton
                style={{
                    height: '15px',
                    width: '300px',
                    marginBottom: '6px',
                    marginTop: '0px'
                }}
            />
        </LoadingCardIndividualSearch>
        <LoadingCardIndividualSearch>
            <Skeleton
                style={{ height: '20px', width: '100px', marginBottom: '6px' }}
            />
            <Skeleton
                style={{ height: '20px', width: '150px', marginBottom: '15px' }}
            />
            <Skeleton
                style={{ height: '30px', width: '100%', marginBottom: '6px' }}
                count={2}
            />
            <Skeleton
                style={{
                    height: '15px',
                    width: '300px',
                    marginBottom: '6px',
                    marginTop: '0px'
                }}
            />
        </LoadingCardIndividualSearch>
    </LoadingSubhomeContainer>
)

export const LoadingTagPage = () => (
    <>
        <LoadingEditorialTitle>
            <Skeleton
                style={{ height: '16px', width: '240px', marginBottom: '6px' }}
            />
            <Skeleton
                style={{ height: '40px', width: '355px', marginBottom: '6px' }}
            />
        </LoadingEditorialTitle>
        <LoadingSubhomeContainer>
            <LoadingCardIndividualSearch>
                <Skeleton
                    style={{
                        height: '20px',
                        width: '100px',
                        marginBottom: '6px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '20px',
                        width: '150px',
                        marginBottom: '15px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '30px',
                        width: '100%',
                        marginBottom: '6px'
                    }}
                    count={2}
                />
                <Skeleton
                    style={{
                        height: '15px',
                        width: '300px',
                        marginBottom: '6px',
                        marginTop: '0px'
                    }}
                />
            </LoadingCardIndividualSearch>
            <LoadingCardIndividualSearch>
                <Skeleton
                    style={{
                        height: '20px',
                        width: '100px',
                        marginBottom: '6px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '20px',
                        width: '150px',
                        marginBottom: '15px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '30px',
                        width: '100%',
                        marginBottom: '6px'
                    }}
                    count={2}
                />
                <Skeleton
                    style={{
                        height: '15px',
                        width: '300px',
                        marginBottom: '6px',
                        marginTop: '0px'
                    }}
                />
            </LoadingCardIndividualSearch>
            <LoadingCardIndividualSearch>
                <Skeleton
                    style={{
                        height: '20px',
                        width: '100px',
                        marginBottom: '6px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '20px',
                        width: '150px',
                        marginBottom: '15px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '30px',
                        width: '100%',
                        marginBottom: '6px'
                    }}
                    count={2}
                />
                <Skeleton
                    style={{
                        height: '15px',
                        width: '300px',
                        marginBottom: '6px',
                        marginTop: '0px'
                    }}
                />
            </LoadingCardIndividualSearch>
            <LoadingCardIndividualSearch>
                <Skeleton
                    style={{
                        height: '20px',
                        width: '100px',
                        marginBottom: '6px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '20px',
                        width: '150px',
                        marginBottom: '15px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '30px',
                        width: '100%',
                        marginBottom: '6px'
                    }}
                    count={2}
                />
                <Skeleton
                    style={{
                        height: '15px',
                        width: '300px',
                        marginBottom: '6px',
                        marginTop: '0px'
                    }}
                />
            </LoadingCardIndividualSearch>
            <LoadingCardIndividualSearch>
                <Skeleton
                    style={{
                        height: '20px',
                        width: '100px',
                        marginBottom: '6px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '20px',
                        width: '150px',
                        marginBottom: '15px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '30px',
                        width: '100%',
                        marginBottom: '6px'
                    }}
                    count={2}
                />
                <Skeleton
                    style={{
                        height: '15px',
                        width: '300px',
                        marginBottom: '6px',
                        marginTop: '0px'
                    }}
                />
            </LoadingCardIndividualSearch>
        </LoadingSubhomeContainer>
    </>
)

export const LoadingPost = () => (
    <ContainerRoot id='skeleton'>
        <Skeleton
            style={{ height: '16px', width: '241px', marginBottom: '50px' }}
        />

        <Skeleton
            style={{ height: '28px', width: '128px', marginBottom: '5px' }}
        />
        <Skeleton
            style={{ height: '40px', width: '100%', marginBottom: '5px' }}
            count={1}
        />
        <Skeleton
            style={{ height: '40px', width: '100%', marginBottom: '15px' }}
        />

        <Skeleton
            style={{ height: '28px', width: '100%', marginBottom: '5px' }}
        />
        <Skeleton
            style={{ height: '28px', width: '100%', marginBottom: '30px' }}
        />

        <Skeleton
            style={{ height: '24px', width: '82px', marginBottom: '5px' }}
        />
        <Skeleton
            style={{ height: '20px', width: '177px', marginBottom: '5px' }}
        />
        <Skeleton
            style={{ height: '16px', width: '187px', marginBottom: '50px' }}
        />

        <Skeleton
            style={{ height: '428px', width: '100%', marginBottom: '5px' }}
        />
        <Skeleton
            style={{ height: '20px', width: '70%', marginBottom: '50px' }}
        />

        <Skeleton
            style={{ height: '28px', width: '100%', marginBottom: '5px' }}
        />
        <Skeleton
            style={{ height: '28px', width: '100%', marginBottom: '5px' }}
        />
        <Skeleton
            style={{ height: '28px', width: '100%', marginBottom: '5px' }}
        />
        <Skeleton
            style={{ height: '28px', width: '50%', marginBottom: '30px' }}
        />
    </ContainerRoot>
)

const LoadingSearchContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 44px;
    margin-bottom: 44px;
    margin-top: 0;
    padding: 0;
    width: 100%;

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.SM_PX}) {
        grid-template-columns: 1fr;
    }
`

const LoadingCardIndividualSearch = styled.div`
    width: 486px,
    marginBottom: 10px;
    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.SM_PX}) {
        width: 100%;
    }
`

const ContainerLoading = styled.div`
    display: grid;
    padding: 0px 0px;
    gap: 18px;
    grid-template-columns: repeat(3, 1fr);
    -webkit-box-align: stretch;
    align-items: stretch;
    margin: 0px;

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.SM_PX}) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const ContainerRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding: 44px;
    box-sizing: border-box;
    width: 100%;
    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.SM_PX}) {
        padding: 18px;
    }
`

const ContainerLoadingFlexRow = styled.div`
    display: flex;
    flex-direction: row;
`

const ContainerLoadingFlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`
const Line = styled.hr`
    border: none;
    border-bottom: 1px solid #f2f2f2;
    width: 100%;
    margin: 44px 0;
`
const LoadingSubhomeContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 44px;
    margin-bottom: 44px;
    margin-top: 44px;
    width: 100%;
`
const LoadingEditorialTitle = styled.div`
    padding-top: 24px;
`
